$mobile: 768px;
section#first.visible {
  height: 100vh;
  width: calc(100% - 120px);
  padding: 0px 60px;
  flex-flow: row;
   @media screen and (max-width: $mobile) {
     flex-flow: column;
     height: auto;
     padding: 0;
     width: 100%;
     align-items: center;
     overflow: hidden;
    }
  aside {
    position: relative;
    top: 0;
    right: 0;
    width: 60%;
    height: 100%;
    overflow: visible;
    aspect-ratio: 1;
    @media screen and (max-width: $mobile) {
     flex-flow: column;
     height: auto;
     width: 100%;
     
    }
  }
  article {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 28px;
    overflow: hidden;
    position: relative;
    @media screen and (max-width: $mobile) {
      padding: 35px 20px 10px 20px;
      align-items: center;
      width: calc(100% - 40px);
      
      height: auto;
      padding-top: 60px;
    }
    .divider {
      width: 40px;
      height: 5px;
      border-radius: 20px;
      background: #a93d17;
      transform-origin: left center;
    }

    h1 {
      color: #a93d17;
      font-family: Raleway;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 42px; /* 131.25% */
      letter-spacing: 1.536px;
      text-transform: lowercase;
      max-width: 460px;
      display: inline;
      z-index: 1;
      @media screen and (max-width: $mobile) {
        color: #a93d17;
        text-align: center;
        font-family: Raleway;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px; /* 128.571% */
        letter-spacing: 1.344px;
        text-transform: lowercase;
        max-width: 190px;
      }
    }
    p {
      color: #ae602b;
      font-family: Raleway;
      font-size: 17px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px; /* 176.471% */
      letter-spacing: 1.7px;
      text-transform: lowercase;
      max-width: 440px;
      z-index: 1;
      @media screen and (max-width: $mobile) {
        color: #ae602b;
        text-align: center;
        font-family: Raleway;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 175% */
        letter-spacing: 1.6px;
        text-transform: lowercase;
        max-width: 300px;
      }
    }
    
  }
}

@keyframes fly {
  0% {
    transform: scale(1.1) translateX(-2%);
  }
  // 50% {
  //     transform: scale(1.08) translateX(0%);
  // }
  100% {
    transform: scale(1.1) translateX(2%);
  }
}
@keyframes generate-pin {
  0% {
    transform: scale(0.2) rotate(-150deg) translateY(0px);
    opacity: 0;
  }
  70% {
    transform: scale(1.1) rotate(20deg) translateY(-20px);
    opacity: 1;
  }
  100% {
    transform: scale(1) rotate(0deg) translateY(0px);
    opacity: 1;
  }
}

@keyframes generate-up-pin {
  0% {
    transform: scale(0.2) translateY(0px);
    opacity: 0;
  }
  70% {
    transform: scale(1.1) translateY(-20px);
    opacity: 1;
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
}

@keyframes generate-base-pin {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes scaleY {
  0% {
    transform: scaleY(0.2);
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    opacity: 1;
  }
}
