.up-anim {
    animation: upFade 0.7s cubic-bezier(0, 0, 0.33, 0.94) both !important;
    animation-delay: var(--delay) !important;
}
.down-anim {
    animation: downFade 0.7s cubic-bezier(0, 0, 0.33, 0.94) both !important;
    animation-delay: var(--delay) !important;
}
.scale-anim {
    animation: scale 0.7s cubic-bezier(0, 0, 0.33, 0.94) both !important;
    animation-delay: var(--delay) !important;
}
.scalex-anim {
    animation: scaleX 0.7s cubic-bezier(0, 0, 0.33, 0.94) both !important;
    animation-delay: var(--delay) !important;
}
.right-anim {
    animation: rlFade 0.7s cubic-bezier(0, 0, 0.33, 0.94) both !important;
    animation-delay: var(--delay) !important;
}
.visible:not(#content, #title) p,
.visible:not(#content, #title) a,
.visible:not(#content, #title) h1,
.visible:not(#content, #title) h2,
.visible:not(#content, #title) h3,
.visible:not(#content, #title) h4,
.visible:not(#content, #title) span,
.visible:not(#content, #title) img {
    animation: rlFade 0.9s cubic-bezier(0.51, 0.04, 0.22, 0.99) both;
    animation-delay: var(--delay);
}

:root {
    --up-anim: upFade 0.7s cubic-bezier(0, 0, 0.33, 0.94);
    --down-anim: downFade 0.7s cubic-bezier(0, 0, 0.33, 0.94);
    --rl-anim: rlFade 0.7s cubic-bezier(0, 0, 0.33, 0.94);
    --rl-anim: lrFadeIn 0.7s cubic-bezier(0, 0, 0.33, 0.94);
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes rlFade {
    0% {
        transform: translateX(-5%);
        opacity: 0;
    }
    30% {
        transform: translateX(-5%);
        opacity: 0;
    }
    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}
@keyframes lrFadeIn {
    0% {
        opacity: 0;
        transform: translateX(10%);
    }
    30% {
        opacity: 0;
        transform: translateX(10%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes upFade {
    0% {
        transform: translateY(10%);
        opacity: 0;
    }
    30% {
        transform: translateY(10%);
        opacity: 0;
    }
    to {
        transform: translateY(0%);
        opacity: 1;
    }
}
@keyframes downFade {
    0% {
        transform: translateY(-10%);
        opacity: 0;
    }
    30% {
        transform: translateY(-10%);
        opacity: 0;
    }
    to {
        transform: translateY(0%);
        opacity: 1;
    }
}
@keyframes fade {
    0% {
        opacity: 0;
    }
    30% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes scale {
    0% {
        transform: scaleY(0%);
        opacity: 0;
    }
    30% {
        transform: scaleY(0%);
        opacity: 0;
    }
    to {
        transform: scaleY(100%);
        opacity: 1;
    }
}
@keyframes scaleX {
    0% {
        transform: scaleX(0%);
        opacity: 0;
    }
    30% {
        transform: scaleX(0%);
        opacity: 0;
    }
    to {
        transform: scaleX(100%);
        opacity: 1;
    }
}
// Animação que faz o texto aparecer da esquerda para a direita em 10 segundos em loop cobrindo a tela inteira
@keyframes tarjaMove {
    0% {
        transform: translateX(50%);
        opacity: 0;
    }
    5% {
        opacity: 0;
    }
    25%,
    85% {
        opacity: 1;
    }
    100% {
        transform: translateX(-50%);
        opacity: 0;
    }
}

// #tarja {
//     animation: tarjaMove 10s linear infinite;
// }
