@import url(./assets/sass/animations.scss);
$mobile: 768px;
html {
  scroll-behavior: smooth;
}
body,
body * {
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: column;
  transition: 0.2s;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  font-family: Raleway, 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  a {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    &:hover {
      color: var(--glow-green, #29d697);
    }
  }
}
body {
  overflow: hidden;
  background-color: #fcf8fc;
  @media screen and (max-width: $mobile) {
    overflow-x: hidden;
    overflow-y: auto;
  }
}
