$mobile: 768px;
footer {
      flex-flow: column;
      z-index: 1;
      span {
        color: #a93d17;
        font-family: Raleway;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 25px; /* 156.25% */
        letter-spacing: 0.768px;
        text-transform: lowercase;
        @media screen and (max-width: $mobile) {
          color: #a93d17;
          font-family: Raleway;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 25px; /* 156.25% */
          letter-spacing: 0.768px;
          text-transform: lowercase;
        }
      }
      figure {
        flex-flow: row;
      }
      @media screen and (max-width: $mobile) {
        justify-content: center;
      }
      &.mobile{
        display: none;
      }
      @media screen and (max-width: $mobile) {
        &.mobile{
         display: flex;
         margin: 50px 0;
         
      }
      &.desktop{
        display: none;
      }
    }
}