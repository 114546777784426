$mobile: 768px;
.orbit {
  perspective: 1000px; // Isso aplica a perspectiva no elemento pai para afetar os filhos.
  height: 100%;
  position: relative; // Assegura que o posicionamento é relativo para os filhos absolutamente posicionados.
  animation: floatOrbit 20s ease-in-out infinite;
  transform-style: preserve-3d;
  @media screen and (max-width: $mobile) {
    width: 100%;
    
  }
  img.circles {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -10;
    width: 100%;
    height: 100%;
    object-fit: cover;
    animation: circle 10s infinite alternate !important;
    animation-timing-function: cubic-bezier(0.36, 0.02, 0.08, 1) !important;
    transform-origin: center center;
    @media screen and (max-width: $mobile) {
      animation: circle-mobile 10s infinite alternate !important;
    }
  }
  figure.screens {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    height: 70%;
    object-fit: cover;
    transform: translate(-50%, -50%); /* Centraliza o elemento */
    animation: screens 2s both !important;
    animation-timing-function: cubic-bezier(0.51, 0.04, 0.22, 0.99) !important;
    transform-origin: center bottom; /* Altera o eixo de rotação para o fundo do elemento */
    animation-delay: 0.5s !important;
    transform-style: preserve-3d;
    picture {
      height: 100%;
      width: min-content;
      position: absolute;
      top: 50%;
      left: 50%;
      transform-origin: center 200%;
      animation: screen1 2s both !important;
      animation-delay: 2s !important;
      transform-style: preserve-3d;
      will-change: transform;
      animation-timing-function: cubic-bezier(
        0.51,
        0.04,
        0.22,
        0.99
      ) !important;
      img {
        height: 100%;
        transform-style: preserve-3d;
      }
      &:nth-child(2) {
        animation: screen2 1s both !important;
        animation-delay: 2.1s !important;
      }
    }
  }
  aside {
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) translateZ(100px) scale(0.9) !important;
    width: 100%;
    height: 100%;
    z-index: 3;
    animation: floatOrbit 10s ease-in-out infinite reverse !important;
    @media screen and (max-width: $mobile) {
      width: 70% !important;
      height: 200% !important;
      transform: translate(-60%, -50%) translateZ(100px) scale(0.9) !important;
    }
    picture {
      position: absolute;
      width: min-content;
      animation: generate-up-pin 0.2s var(--delay) both !important;

      &:nth-child(1) {
        top: 65%;
        left: 70%;
         @media screen and (max-width: $mobile) {
          left: 50%;
        }
      }
      &:nth-child(2) {
        top: 18%;
        left: 79%;
      }
      &:nth-child(3) {
        @media screen and (max-width: $mobile) {
          top: 10%;
        }
        top: 20%;
        left: -10%;
      }
    }
  }
  picture img {
    animation: float-self calc((var(--rand) / 100) * 30s) ease-in-out infinite !important;
  }
}
@keyframes circle {
  0% {
    transform: translate(-50%, -50%) translateZ(-200px) scale(0.9);
  }
  100% {
    transform: translate(-50%, -50%) translateZ(-200px) scale(1.2);
  }
}
@keyframes circle-mobile {
  0% {
    transform: translate(-50%, -50%) translateZ(-200px) scale(1.5);
  }
  100% {
    transform: translate(-50%, -50%) translateZ(-200px) scale(2);
  }
}

@keyframes screens {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) translateZ(-1000px) rotateX(-80deg);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

@keyframes screen1 {
  0% {
    transform: translate3d(-50%, -50%, -200px); // Simplesmente use translate3d
  }
  100% {
    transform: translate3d(-50%, -50%, -200px) rotate(8deg); // Continua com translate3d e adiciona rotação
  }
}
@keyframes screen2 {
  0% {
    transform: translate3d(-50%, -50%, 0px) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0px) rotate(-8deg);
  }
}

@keyframes generate-up-pin {
  0% {
    transform: scale(0.2) translateY(0px);
    opacity: 0;
  }
  70% {
    transform: scale(1.1) translateY(-20px);
    opacity: 1;
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
}

@keyframes floatOrbit {
  0%,
  100% {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }
  25% {
    transform: rotateX(2deg) rotateY(5deg) rotateZ(2deg);
  }
  50% {
    transform: rotateX(-2deg) rotateY(-2deg) rotateZ(-2deg);
  }
  75% {
    transform: rotateX(2deg) rotateY(-2deg) rotateZ(2deg);
  }
}
@keyframes float-self {
  0%,
  100% {
    transform: rotateX(1deg) rotateY(0deg) rotateZ(1deg) translate3d(0, 0, 0);
  }
  25% {
    transform: rotateX(3deg) rotateY(2deg) rotateZ(2deg)
      translate3d(20px, -15px, 10px);
  }
  50% {
    transform: rotateX(-2deg) rotateY(-3deg) rotateZ(-2deg)
      translate3d(-20px, 15px, -10px);
  }
  75% {
    transform: rotateX(2deg) rotateY(-2deg) rotateZ(2deg)
      translate3d(15px, -10px, 5px);
  }
}
